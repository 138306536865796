import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Navigation from "../components/Navigation/Navigation";
import styles from "../components/WorkSlider/WorkSlider.module.scss";
import { data } from "../data/data"; // Import your data
import Gallery from "../components/Front";

function FrontPage() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [video, setVideo] = useState("");
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [firstVisit, setFirstVisit] = useState(false);
  const words = ["animation", "visual", "sequence", "frame", "motion"];

  useEffect(() => {
    const isFirstVisit = sessionStorage.getItem("firstVisit");
    if (!isFirstVisit) {
      setFirstVisit(true);
      sessionStorage.setItem("firstVisit", true);
    }

    const interval = setInterval(() => {
      setLoadingPercentage((prevPercentage) => {
        const newPercentage = prevPercentage + 1;
        if (newPercentage >= 100) {
          clearInterval(interval);
          setLoaded(true);
          return 100;
        }
        return newPercentage;
      });
    }, 40);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isMobile && data.length > 0) {
      setVideo(data[0].video);
    }
  }, [isMobile]);

  useEffect(() => {
    const wordInterval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 400);

    return () => clearInterval(wordInterval);
  }, []);

  useEffect(() => {
    if (!loaded) {
      data.forEach((item) => {
        const videoElement = document.createElement("video");
        videoElement.src = item.video;
        videoElement.preload = "auto";

        const mainImageElement = new Image();
        mainImageElement.src = item.mainImage;

        if (item.gallery) {
          item.gallery.forEach((imageUrl) => {
            const galleryImageElement = new Image();
            galleryImageElement.src = imageUrl;
          });
        }
      });
    }
  }, [loaded]);

  return (
    <div className={styles.main}>
      {firstVisit && (
        <div
          className={`${styles.loader} ${
            loadingPercentage < 100 ? styles.shownLoader : styles.hiddenLoader
          }`}
        >
          <h1>{loadingPercentage}</h1>
          <p>
            One{" "}
            {!loaded ? (
              <span>{words[currentWordIndex]}</span>
            ) : (
              <span>frame</span>
            )}{" "}
            at a time <span className={styles.dot}>•</span>
          </p>
        </div>
      )}
      <>
    <Navigation />
        <Gallery />
      </>
    </div>
  );
}

export default FrontPage;
