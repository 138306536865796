import ContactForm from "../components/AboutPage/Contact";
import Navigation from "../components/Navigation/Navigation";

export default function ContactPage(){
    return(
        <>
        <Navigation links={true}/>
        <ContactForm />
        </>
    )
}