import Navigation from "../Navigation/Navigation";
import styles from "./AboutPage.module.scss";
import { ReactComponent as Round } from "../../assets/round.svg";
import avatar from "../../assets/avatar1.png";
import Bio from "./Bio";
import ContactForm from "./Contact";
import Lottie from 'lottie-react';
import knowDark from "../../files/knowDark.json";
import knowLight from "../../files/knowLight.json";
export default function AboutPage() {
  const theme = localStorage.getItem("darkThemeEnabled");
  return (
    <div className={styles.container}>
      <Navigation links={true} />
      <div className={styles.content}>
       {theme === "true" ? 
        <Lottie
        animationData={ knowLight}
        loop={true} 
        autoplay={true} 
      />
      :
      <Lottie
      animationData={knowDark} 
      loop={true} 
      autoplay={true} 
    />
       }
      </div>
     <div className={styles.bioContainer}>
     <Bio />
     </div>
    </div>
  );
}
