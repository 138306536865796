import React, { useEffect, useRef, useState } from 'react';
import styles from './Gallery.module.scss';
import { data } from '../../data/data';
import { useNavigate } from 'react-router';

const Gallery = () => {
    const [hoverIndex, setHoverIndex] = useState(2);
    const [title, setTitle] = useState(data[hoverIndex]?.title);
    const videoRefs = useRef(new Array(data.length).fill(null));
    const [isTransitioning, setIsTransitioning] = useState(false);

    useEffect(() => {
        setTitle(data[hoverIndex]?.title);
    }, [hoverIndex]);

    const handleMouseEnter = index => {
        setHoverIndex(index);
        setIsTransitioning(true);
        videoRefs.current.forEach((video, idx) => {
            if (idx !== index && video) video.pause();
        });
        tryPlayingVideo(index);
    };

    const tryPlayingVideo = index => {
        const video = videoRefs.current[index];
        setTitle(data[index]?.title);
        if (video) {
            const playPromise = video.play();
            if (playPromise !== undefined) {
                playPromise.catch(error => {
                    console.error('Play was interrupted:', error);
                });
            }
        }
    };

    useEffect(() => {
        tryPlayingVideo(2);
    }, []);

    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <div className={styles.gallery}>
                {data.map((item, index) => (
                    <div
                        className={styles.galleryItem}
                        style={{ flex: hoverIndex === index ? "0 1 80%" : "0 1 30px" }}
                        onMouseEnter={() => handleMouseEnter(index)}
                        key={index}
                        onClick={() => navigate(`/${item?.title}`)}
                    >
                        <video
                            ref={el => (videoRefs.current[index] = el)}
                            src={item.thumbnailVideo}
                            alt={item.title}
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            loop
                            muted
                            playsInline
                        />
                        {hoverIndex === index && <p>{title}</p>}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Gallery;
