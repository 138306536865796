import React, { useState, useEffect } from 'react';
import { FiSun, FiMoon } from 'react-icons/fi';
import styles from "./Toggle.module.scss";

const Toggle = ({extraClass}) => {
    const [isDarkTheme, setIsDarkTheme] = useState(false); // Default dark theme is false

    useEffect(() => {
        const darkThemeEnabled = localStorage.getItem('darkThemeEnabled');
        if (darkThemeEnabled === 'true') {
            setIsDarkTheme(true);
            document.body.classList.add('dark-theme');
        } else {
            setIsDarkTheme(false);
            document.body.classList.remove('dark-theme');
        }
    }, []); 

    const handleToggle = () => {
        const newThemeState = !isDarkTheme;
        setIsDarkTheme(newThemeState);
        if (newThemeState) {
            document.body.classList.add('dark-theme');
        } else {
            document.body.classList.remove('dark-theme');
        }
        localStorage.setItem('darkThemeEnabled', newThemeState.toString());
    };

    return (
        <div className={`${styles.toggle} ${isDarkTheme ? styles.checked : ''} ${styles[extraClass]}`} onClick={handleToggle}>
            <div className={styles.toggleHandle}></div>
        </div>
    );
};

export default Toggle;
