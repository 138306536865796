import Val1 from "../assets/val1.mp4";
import Val2 from "../assets/val2.mp4";
import Val3 from "../assets/val3.mp4";
import AM3 from "../assets/3AM.mp4";
import Tesla from "../assets/Tesla_Shot_1.mp4";




export const data = [
  {
    title: "Nike",
    video: AM3,
    thumbnailVideo:AM3,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    gallery: [
      "https://images.prismic.io/field-day-sound/b80482d8-95d9-4af1-8908-1bc92f5d5b45_tax_08.jpg?auto=compress,format",
      "https://images.prismic.io/field-day-sound/e4f6edab-28c0-4a7d-a1a1-c56951a7606d_tax_03.jpg?auto=compress,format",
    ],
    mainImage:
      "https://images.prismic.io/field-day-sound/d89e0015-1f68-4c8b-84e1-275757bfc65c_tax_04.jpg?auto=compress,format",
  },
  {
    title: "Tesla",
    video: Tesla,
    thumbnailVideo:Tesla,
    description: "Tesla Video Demo",
    gallery: [
      "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1713280916/Frame_18_2_xvdh6b.png",
      "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1713280787/Frame_27_bxftug.png",
    ],
    mainImage:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1713274608/Templates/isolated-shot-pleasant-looking-cheerful-beautiful-brunette-posing-against-pink-wall_bzuxyh.jpg"
  },
  {
    title: "Clash",
    video: Val3,
    thumbnailVideo:Val3,
    mainImage: "https://images.prismic.io/field-day-sound/d92d07ff-e92e-4246-9434-600a7ea5d5d8_what+the+football.jpg?auto=compress,format",
  },
  {
    title: "hp",
    video: Val1,
    thumbnailVideo:Val1,
    mainImage:"https://images.prismic.io/field-day-sound/b80482d8-95d9-4af1-8908-1bc92f5d5b45_tax_08.jpg?auto=compress,format",
  },
  {
    title: "Turbotax",
    video: Val2,
    thumbnailVideo:Val2,
    mainImage:"https://images.prismic.io/field-day-sound/9bc71159-1fa3-468b-af16-4acafff41710_what+the+football+2.jpg?auto=compress,format"
  },
  {
    title: "vrbo",
    video: Val3,
    thumbnailVideo:Val3,
    mainImage:"https://images.prismic.io/field-day-sound/d89e0015-1f68-4c8b-84e1-275757bfc65c_tax_04.jpg?auto=compress,format",
  },
  {
    title: "Clash",
    video: Val3,
    thumbnailVideo:Val3,
    mainImage: "https://images.prismic.io/field-day-sound/d92d07ff-e92e-4246-9434-600a7ea5d5d8_what+the+football.jpg?auto=compress,format",
  },
  {
    title: "hp",
    video: Val1,
    thumbnailVideo:Val1,
    mainImage:"https://images.prismic.io/field-day-sound/b80482d8-95d9-4af1-8908-1bc92f5d5b45_tax_08.jpg?auto=compress,format",
  },
  {
    title: "Turbotax",
    video: Val2,
    thumbnailVideo:Val2,
    mainImage:"https://images.prismic.io/field-day-sound/9bc71159-1fa3-468b-af16-4acafff41710_what+the+football+2.jpg?auto=compress,format"
  },
  {
    title: "vrbo",
    video: Val3,
    thumbnailVideo:Val3,
    mainImage:"https://images.prismic.io/field-day-sound/d89e0015-1f68-4c8b-84e1-275757bfc65c_tax_04.jpg?auto=compress,format",
  },
  {
    title: "Clash",
    video: Val3,
    thumbnailVideo:Val3,
    mainImage: "https://images.prismic.io/field-day-sound/d92d07ff-e92e-4246-9434-600a7ea5d5d8_what+the+football.jpg?auto=compress,format",
  },
  {
    title: "hp",
    video: Val1,
    thumbnailVideo:Val1,
    mainImage:"https://images.prismic.io/field-day-sound/b80482d8-95d9-4af1-8908-1bc92f5d5b45_tax_08.jpg?auto=compress,format",
  },
  {
    title: "Turbotax",
    video: Val2,
    thumbnailVideo:Val2,
    mainImage:"https://images.prismic.io/field-day-sound/9bc71159-1fa3-468b-af16-4acafff41710_what+the+football+2.jpg?auto=compress,format"
  },
  {
    title: "vrbo",
    video: Val3,
    thumbnailVideo:Val3,
    mainImage:"https://images.prismic.io/field-day-sound/d89e0015-1f68-4c8b-84e1-275757bfc65c_tax_04.jpg?auto=compress,format",
  },
  {
    title: "Clash",
    video: Val3,
    thumbnailVideo:Val3,
    mainImage: "https://images.prismic.io/field-day-sound/d92d07ff-e92e-4246-9434-600a7ea5d5d8_what+the+football.jpg?auto=compress,format",
  },
  {
    title: "hp",
    video: Val1,
    thumbnailVideo:Val1,
    mainImage:"https://images.prismic.io/field-day-sound/b80482d8-95d9-4af1-8908-1bc92f5d5b45_tax_08.jpg?auto=compress,format",
  },

];
