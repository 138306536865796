import Navigation from "../components/Navigation/Navigation";
import styles from "./SinglePage.module.scss";
const SinglePage = ({ title, video, description, gallery, mainImage }) => {
  return (
    <>
      <Navigation links={true} />
      <div className={styles.container}>
        <h1>{title}</h1>
        <p>{description}</p>
        <video
          controls={true}
          className={styles.video}
          loop
          muted
          data-aos="zoom-in"
          src={video}
        />
        <div data-aos="zoom-in" className={styles.gallery}>
          {gallery?.map((image, index) => (
            <img key={index} src={image} alt="gallery" />
          ))}
        </div>
        {mainImage && (
          <div data-aos="zoom-in" className={styles.mainImage}>
            <img src={mainImage} alt="main" />
          </div>
        )}
      </div>
    </>
  );
};
export default SinglePage;
