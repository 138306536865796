import './App.css';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FrontPage from './pages/FrontPage';
import { data } from './data/data';
import SinglePage from './pages/SinglePage';
import ProjectsList from './components/ProjectsList/ProjectsList';
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from 'react';
import AboutPage from './components/AboutPage/AboutPage';
import ContactPage from './pages/Contact';
function App() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
}, []);
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<FrontPage/>} />
          <Route exact path="/about" element={<AboutPage/>} />
          <Route exact path="/contact" element={<ContactPage/>} />
          <Route exact path="/projects" element={<ProjectsList/>} />
          {data.map((item, index) => (
            <Route
              key={index}
              path={`/${item.title}`}
              element={<SinglePage
              mainImage={item?.mainImage}
              description={item?.description}
              title={item?.title} video={item?.video} 
              gallery={item?.gallery}
              />
              }
            />
          ))}
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;
