import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import styles from "./AboutPage.module.scss";
import avatar from "../../assets/avatar1.png";
import Lottie from 'lottie-react';
import touchDark from "../../files/touchDark.json";
import touchLight from "../../files/touchLight.json";
export default function ContactForm() {
  const [state, handleSubmit] = useForm("mzbnvqdl");
  const theme = localStorage.getItem("darkThemeEnabled");
  return (
    <>
      <div className={styles.contact}>
        <div className={styles.leftText}>
        {
          theme === "true" ?
          <Lottie
          animationData={touchLight} 
          loop={true} 
          autoplay={true} 
        />
        :
        <Lottie
        animationData={touchDark} 
        loop={false} 
        autoplay={true} 
      />
         }
        </div>
        <div className={styles.rightside}>
     {state?.succeeded ?
     <div className={styles.sentModal}>
     <div data-aos="zoom-in" className={styles.frame}>
         <img src={avatar} alt="avatar" />
         <h1>Thank you for your message!</h1>
         <p>Will come back to you shortly.</p>
     </div>
   </div>
     :
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">Email Address</label>
          <input id="email" type="email" name="email" />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
          <label>Message</label>
          <textarea id="message" name="message" />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
          <button type="submit" disabled={state.submitting}>
            Submit
          </button>
        </form>
     }
          <div className={styles.links}>
          <a rel="noreferrer" href="https://www.behance.net/valgashi" target="_blank">Behance</a>
        <a rel="noreferrer" href="https://www.linkedin.com/in/valmirgashi/" target="_blank">LinkedIn</a>
          </div>
          </div>
      </div>
    </>
  );
}
