import { useNavigate } from "react-router";
import { data } from "../../data/data";
import Navigation from "../Navigation/Navigation";
import styles from "./ProjectsList.module.scss";
export default function ProjectsList(){
    const navigate = useNavigate();
    return(
        <div className={styles.ProjectsList}>
            <Navigation links={false} />
            <h1>If it’s digital, I can make it.</h1>
            <div className={styles.list}>
                {data.map((item, index) => (
                    <div data-aos="zoom-in" onClick={()=>navigate(`/${item?.title}`)} key={index} className={styles.item}>
                        <img src={item?.mainImage} alt="main" />
                   <div className={styles.title}>
                    <h2>{item?.title}</h2>
                   </div>
                    </div>
                ))
                    }
            </div>
        </div>
    )
}