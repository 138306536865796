import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Navigation.module.scss";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { IoCloseOutline } from "react-icons/io5";
import Toggle from "../Toggle/Toggle";
import { ReactComponent as Slogan } from "../../assets/slogan.svg";
function Navigation({ extraClass }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className={`${styles.container} ${isScrolled && styles.bgNav} ${
          styles[extraClass]
        }`}
      >
        <div
          className={`${styles.inner} ${
            window.location.pathname === "/" && styles.home
          }`}
        >
          <Logo onClick={() => navigate("/")} />
          <div className={styles.sublinks}>
            <div className={styles.columnlinks}>
              <Link
                className={
                  window?.location?.pathname === "/about" && styles.active
                }
                to="/about"
              >
                About
              </Link>
              <Link
                className={
                  window?.location?.pathname === "/contact" && styles.active
                }
                to="/contact"
              >
                Contact
              </Link>
            </div>
            <p>
              One <span>frame</span> at a time
            </p>
          </div>
        </div>
        {window.location.pathname === "/" && <Toggle extraClass={extraClass} />}
      </div>
      <div
        className={`${styles.modalNavigation} ${
          showModal && styles.showNavigation
        }`}
      >
        <Logo className={styles.logo} onClick={() => navigate("/")} />
        <IoCloseOutline
          onClick={() => setShowModal(false)}
          className={styles.close}
        />
        <div className={`${styles.left} ${showModal && styles.fromBottom}`}>
          <Link
            className={window?.location?.pathname === "/" && styles.active}
            to="/"
          >
            Home
          </Link>
          <Link
            className={window?.location?.pathname === "/about" && styles.active}
            to="/about"
          >
            About
          </Link>
          <Link
            className={
              window?.location?.pathname === "/projects" && styles.active
            }
            to="/projects"
          >
            Projects
          </Link>
          <Link
            className={
              window?.location?.pathname === "/contact" && styles.active
            }
            to="/contact"
          >
            Contact
          </Link>
        </div>
        <div className={`${styles.right} ${showModal && styles.fromTop}`}>
          <a
            rel="noreferrer"
            href="https://www.behance.net/valgashi"
            target="_blank"
          >
            Behance
          </a>
          <a
            rel="noreferrer"
            href="https://www.linkedin.com/in/valmirgashi/"
            target="_blank"
          >
            LinkedIn
          </a>
          <Toggle />
        </div>
      </div>
    </>
  );
}

export default Navigation;
