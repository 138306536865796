import { useState } from "react";
import styles from "./styles.module.scss";
export default function Bio() {
  const [isHovered, setIsHovered] = useState(null);

  const handleMouseOver = (index) => {
    setIsHovered(index);
  };

  const handleMouseOut = (index) => {
    setIsHovered(index);
  };
  const data = [
    {
      name: "Adobe Photoshop",
      percentage: "95%",
    },
    {
      name: "Adobe Illustrator",
      percentage: "60%",
    },
    {
      name: "Adobe After Effects ",
      percentage: "90%",
    },
    {
      name: "Cinema 4D",
      percentage: "70%",
    },
    {
      name: "Redshift",
      percentage: "85%",
    },
    {
      name: "Unreal Engine",
      percentage: "65%",
    },
    {
      name:"Substance Painter",
      percentage:"50%"
    },
    {
      name:"Maya",
      percentage:"85%"
    }
  ];
  return (
    <div className={styles.main}>
      <div className={styles.bio__content}>
        <div className={styles.left}>
          <div className={styles.circle}>
            <p>1</p>
          </div>
          <h3>Bio</h3>
        </div>
        <div className={styles.right}>
          <p className={styles.bioText}>
            {" "}
            Welcome to my creative realm! I'm Val Gashi, a versatile digital
            artist specializing in 3D art, motion design, editing, and various
            forms of digital expression. With a passion for visual storytelling
            and a keen eye for detail, I bring ideas to life through the fusion
            of technology and creativity.
          </p>
        </div>
      </div>
      <div
        className={`${styles.bio__content} ${styles.experienceItem} ${styles.exp__content}`}
      >
        <div className={styles.left}>
          <div className={styles.circle}>
            <p>2</p>
          </div>
          <h3>Experience</h3>
        </div>
        <div className={styles.right}>
          <div className={styles.item}>
            <div className={styles.flex}>
              <h4>VFX / EDITOR</h4>
              <span>@EMFCreative</span>
            </div>
            <h4>Jun 14 - Jun 15</h4>
          </div>
          <div className={styles.item}>
            <div className={styles.flex}>
              <h4>MOTION DESIGNER</h4>
              <span>@KutiaTV</span>
            </div>
            <h4>Jul 15 - Sep 16</h4>
          </div>
          <div className={styles.item}>
            <div className={styles.flex}>
              <h4>3D GENERALIST </h4>
              <span>@NeoFilms</span>
            </div>
            <h4>Sep 16 - Aug 20</h4>
          </div>
          <div className={styles.item}>
            <div className={styles.flex}>
              <h4>VR DEV / UI UX</h4>
              <span>@SDS</span>
            </div>
            <h4>Aug 20 - Fev 22</h4>
          </div>
          <div className={styles.item}>
            <div className={styles.flex}>
              <h4>3D MOTION DESIGNER </h4>
              <span>@MasterWiZR</span>
            </div>
            <h4>Feb 22 - Present</h4>
          </div>
        </div>
      </div>
      <div
        className={`${styles.bio__content} ${styles.exp__content} ${styles.skills__content}`}
      >
        <div className={styles.left}>
          <div className={styles.circle}>
            <p>3</p>
          </div>
          <h3>Skills</h3>
        </div>
        <div className={styles.right}>
          {data.map((item, index) => (
            <div
              key={index}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={() => handleMouseOut(index)}
              className={styles.item}
            >
              <h4>{item.name}</h4>
              <h4>{item.percentage}</h4>
              <div
                style={{ width: isHovered === index ? item.percentage : `0` }}
                className={styles.after}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
